import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/display.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            Design has been my passion, and it continues to inspire me every day. 🎨
              <br />
              <br />I am fluent in tools like
              <i>
                <b className="purple"> Figma, Photoshop, and Illustrator. </b>
              </i>
              <br />
              <br />
              My field of interest lies in creating intuitive and &nbsp;
              <i>
                <b className="purple">aesthetically pleasing user interfaces </b> and 
                experiences, with a strong focus on{" "}
                <b className="purple">
                user-centered design.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for crafting designs with   <b className="purple">modern principles</b> and
              <i>
                <b className="purple">
                  {" "}
                  ensuring that each product is both functional
                </b>
              </i>
              &nbsp; and
              <i>
                <b className="purple"> visually appealing.</b>
              </i>
            </p>
          </Col>
          
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              {/* <li className="social-icons">
                <a
                  href="https://github.com/soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://x.com/wytein"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/prathap-kalaivanan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/0wyte/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
