import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "../../Assets/aboutmain.json";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";

function About() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">I'M</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col md={5} style={{ paddingBottom: 20 }}>
            <Lottie
                options={defaultOptions}
                height={400}
                width={400}
              />
            </Col>
        </Row>
        
        {/* <Techstack /> */}

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        {/* <Github /> */}
      </Container>
    </Container>
  );
}

export default About;