import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Prathap Kalaivanan </span>
            from <span className="purple"> Chennai, India.</span>
            <br />
            I am currently employed as a UI/UX Designer at Zeal Zoft Private Limited.
            <br />
            I have completed B.sc Visual Communication at Bharath Institute of Higher
            Education & Research.
            <br />
            <br />
            Apart from designing, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "#0086ce" }}>
          "Design is not just what it looks like and feels like. Design is how it works and connects with people."{" "}
          </p>
          <footer className="blockquote-footer">Prathap</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
